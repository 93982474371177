const API_URL = "https://api.loyalty.appcent.dev/api/";

//admin@admin.com
//Passw0rd
const login = (user: string, password: string) => {
  return fetch(API_URL + "Auth/Login", {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  method: "POST",
  body: JSON.stringify({
    "email": user,
    "password": password,
    "authenticatorCode": "",
    "storeId": 0
  })
});
};

const logout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('expiration');
  return window.location.href = "/";
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  logout,
};