import React, { useEffect, useState } from 'react'
import customer from '../services/customers.service'

function CampaignManagement() {


  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    customer.getCampaigns(0,50).then(response => response.json())
    .then((response) => {
      setCustomers(response.items)
    }).catch(e => {
      return false;
    });
  }, [])

  return (
<section className="h-screen">
  <div className="container h-full px-0 py-0">
        <div className='text-3xl font-semibold text-gray-700'>Campaign Management</div>
        <div className='text-base font-medium text-gray-500'>New Campaign</div>
        <div className='container mt-8 border bg-white rounded-lg min-h-[50%] shadow'>

          <div className="relative overflow-x-auto mx-5 my-5  ">
          <div className="mb-5">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Type</label>
          <div className="flex items-center mb-4">
            <input id="country-option-2" type="radio" name="countries" value="Germany" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"/>
            <label  className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Price
            </label>
          </div>

          <div className="flex items-center mb-4">
            <input id="country-option-3" type="radio" name="countries" value="Spain" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600"/>
            <label  className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Discount
            </label>
          </div>
          </div>

          <div className="mb-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input type="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          <div className="mb-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
            <input type="desc" id="desc" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>


          </div>
          <div className='grid grid-cols-2 m-5'>
          <div className="mb-5 mr-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Item Count</label>
            <input type="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          <div className="mb-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Price</label>
            <input type="desc" id="desc" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          <div className="mb-5 mr-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Store</label>
            <input type="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          <div className="mb-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
            <input type="desc" id="desc" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>

          <div className="mb-5 mr-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Action Product Comment</label>
            <input type="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          <div className="mb-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Action Store Comment</label>
            <input type="desc" id="desc" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          <div className="mb-5 mr-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reward Price</label>
            <input type="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          <div className="mb-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reward Dicsount Rate</label>
            <input type="desc" id="desc" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          
          <div className="mb-5 mr-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reward Item Count</label>
            <input type="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          <div className="mb-5">
            <label   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reward Wallet Price</label>
            <input type="desc" id="desc" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required />
          </div>
          </div>



          <div className='grid grid-cols-2 m-5'>
            <div className='text-left'></div>
            <div className='text-right'><button className=' rounded-md bg-violet-700 px-7 pb-2.5 pt-3 text-sm font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-violet-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-violet-700 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-violet-900 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"'>
              Save
            </button></div>
          </div>
          </div>
  </div>
</section>
  )
}

export default CampaignManagement;
