import React from 'react'

function Notification() {
  return (
<section className="h-screen">
  <div className="container h-full px-0 py-0">
        <div className='text-3xl font-semibold text-gray-700'>Notification</div>
        <div className='text-base font-medium text-gray-500'>Notification / Home</div>
        <div className='container mt-8 border bg-white rounded-lg min-h-[50%] shadow'>
          <div className='grid grid-cols-2 m-5'>
            <div className=''>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="search" className="block p-2 pl-10 text-sm text-grey-800 placeholder-gray-500 border border-gray-300 rounded-md bg-white focus:ring-gray-500 focus:border-gray-500" placeholder="Search on page" />
            </div>
            </div>
            <div className='text-right'><button className=' rounded-md bg-violet-700 px-7 pb-2.5 pt-3 text-sm font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-violet-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-violet-700 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-violet-900 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"'>+ Add Field</button></div>
          </div>
        </div>
  </div>
</section>
  )
}

export default Notification;
