import React, { useEffect, useState } from 'react'
import auth from '../services/auth.service'
import users from '../services/users.service'

function Profile() {

  const [user, setUser] = useState({
    "id": 1,
    "firstName": "",
    "lastName": "",
    "email": "",
    "status": false
  });

  useEffect(() => {
    users.currentUser().then(response => response.json())
    .then((response) => {
      setUser(response)
    }).catch(e => {
      //auth.logout();
      return false;
    });
  }, [])

  return (
<section className="h-screen">
  <div className="container h-full px-0 py-0">
        <div className='text-3xl font-semibold text-gray-700'>Profile</div>
        <div className='text-base font-medium text-gray-500'>Profile / Home</div>
        <div className='container mt-8 border bg-white rounded-lg min-h-[50%] shadow'>
          <div className='grid grid-cols-2 m-5'>
            <div className='text-gray-700 text-xl font-semibold'>Profile Info</div>
            <div className='text-right'><button onClick={() => auth.logout()} className=' rounded-md bg-red-700 px-7 pb-2.5 pt-3 text-sm font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-red-700 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-red-900 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"'>Logout</button></div>
          </div>
          <div className='p-5'>
          {user?.firstName}<br />
          {user?.lastName}<br />
          {user?.email}<br />
          {user?.status ? "Active" : "Passive"}<br />
          </div> 
        </div>
  </div>
</section>
  )
}

export default Profile;
