const API_URL = "https://api.loyalty.appcent.dev/api/";

const getCustomers = (pageIndex: any, pageSize: any) => {
  return fetch(API_URL + "Customers?" + new URLSearchParams({"pageIndex": pageIndex,"pageSize": pageSize}), {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.accessToken
  },
  method: "GET",
});
}


const getCampaigns = (pageIndex: any, pageSize: any) => {
  return fetch(API_URL + "Campaigns?" + new URLSearchParams({"pageIndex": pageIndex,"pageSize": pageSize}), {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.accessToken
  },
  method: "GET",
});
}




const getWallets = (pageIndex: any, pageSize: any) => {
  return fetch(API_URL + "Wallets?" + new URLSearchParams({"pageIndex": pageIndex,"pageSize": pageSize}), {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.accessToken
  },
  method: "GET",
});
}








// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCustomers, getCampaigns, getWallets
};