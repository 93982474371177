const API_URL = "https://api.loyalty.appcent.dev/api/";

//admin@admin.com
//Passw0rd
const currentUser = () => {
  return fetch(API_URL + "Users/GetFromAuth", {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.accessToken
  },
  method: "GET",
});
};

const getUsers = (pageIndex: any, pageSize: any) => {
  return fetch(API_URL + "Customers?" + new URLSearchParams({"pageIndex": pageIndex,"pageSize": pageSize}), {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.accessToken
  },
  method: "GET",
});
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  currentUser, getUsers
};