import React from 'react';
import auth from './services/auth.service'
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Login from './pages/Login'
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard'
import TierManagement from './pages/TierManagement'
import CampaignManagement from './pages/CampaignManagement'
import CampaignAdd from './pages/CampaignAdd'
import CouponManagement from './pages/CouponManagement'
import CustomerManagement from './pages/CustomerManagement'
import WalletManagement from './pages/WalletManagement'
import Notification from './pages/Notification'
import Profile from './pages/Profile'
import Settings from './pages/Settings'

function App() {
  
  if(!localStorage.accessToken){
    return (
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<Login />} />
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
    <Routes>
        <Route path='/' element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path='/tiers' element={<TierManagement />} />

            <Route path='/campaigns/add' element={<CampaignAdd />} />

            <Route path='/campaigns' element={<CampaignManagement />} />


            <Route path='/customers' element={<CustomerManagement />} />
            <Route path='/coupons' element={<CouponManagement />} />
            <Route path='/wallets' element={<WalletManagement />} />
            <Route path='/notification' element={<Notification />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/settings' element={<Settings />} />

        </Route>
    </Routes>
</BrowserRouter>
  );
}

export default App;
