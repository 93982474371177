import { Outlet } from "react-router-dom";
import Sidenav from "../Sidenav";

const Layout = () => {
    return <div className="p-4 sm:ml-64">
        <Sidenav />
        <Outlet />
    </div>;
};

export default Layout;