import React, { useState } from 'react';
import auth from '../services/auth.service'
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({email:"", password: "", login: false, loading: false});
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateEmail = (email:any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  const validate = () => {
    validateEmail(email) ? errors.email = "Email address is invalid." : errors.email = "";
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    errors.login = false;
    e.preventDefault();
      await auth.login(email, password)
      .then(response => response.json())
      .then((response) => {
        console.log(response);
        localStorage.setItem('accessToken', response.accessToken.token);
        localStorage.setItem('expiration', response.accessToken.expiration);
        return window.location.href = "/";
      }).catch(e => {
        console.log("ss");
        errors.login = true;
        setLoading(false);
        return false;
      });
      setLoading(false);
      return false;
    }

  return (
    <section className="h-screen">
      <div className="container h-full px-0 py-0">
        <div className=" flex h-full flex-wrap items-center justify-center lg:justify-between ">
          <div className="md:mb-0 md:w-8/12 lg:w-7/12 h-full loginBanner"></div>
          <div className="md:w-8/12 lg:ml-6 lg:w-4/12">
            <div className="text-5xl font-semibold">Log In</div>
            <div className="text-lg	text-gray-500 my-5">Welcome to Ovo Loyalty</div>
            <form onSubmit={handleSubmit}>
              <div className="relative border-1" data-te-input-wrapper-init>
                  <label className="block">
                    <span className="text-gray-700 font-semibold">Email Address</span>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className={"mt-1 px-3 py-[0.32rem] block min-h-[auto] w-full rounded-md border border-gray-300 " }
                      placeholder="admin@loyalty.ovo.com"
                    required/>
                  </label>
              </div>

              <div className="relative my-6" data-te-input-wrapper-init>
              <label className="block">
                    <span className="text-gray-700 font-semibold">Password</span>
                    <input
                      name="email"
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="mt-1  px-3 py-[0.32rem] block min-h-[auto] w-full rounded-md border border-gray-300"
                      placeholder="********"
                    />
                  </label>
                  <span className="text-gray-500">It must be a combination of minimum 8 letters, numbers and symbols.</span>

              </div>

              <div className="mb-6 flex items-center justify-between">
                <div className="mb-[0.125rem] block min-h-[1.5rem]">
                  <label className="inline-flex items-center">
                    <input
                      name="password"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                    />
                    <span className="font-semibold ml-2 inline-block pl-[0.15rem] hover:cursor-pointer text-gray-700">
                    Remember me
                  </span>
                  </label>
                </div>

                <span
                  className="hover:cursor-pointer font-semibold text-gray-700 transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                  >Forgot password?</span>
              </div>

              <button
                type="submit"
                className="inline-block w-full rounded-md bg-violet-700 px-7 pb-2.5 pt-3 text-sm font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-violet-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-violet-700 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-violet-900 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                data-te-ripple-init
                data-te-ripple-color="light">
                { loading ? "Loading" : "Log In" }
              </button>
              {errors.login && (
                <p className="text-red-500 font-semibold mt-4">
                  Email address or password is incorrect.
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login;
