import React from 'react'

function Settings() {
  return (
<section className="h-screen">
  <div className="container h-full px-0 py-0">
        <div className='text-3xl font-semibold text-gray-700'>Settings</div>
        <div className='text-base font-medium text-gray-500'>Settings / Home</div>
        <div className='container mt-8 border bg-white rounded-lg min-h-[50%] shadow'>
          <div className='grid grid-cols-2 m-5'>
            <div className='text-gray-700 text-xl font-semibold'>All Settings</div>
            <div className='text-right'><button className=' rounded-md bg-violet-700 px-7 pb-2.5 pt-3 text-sm font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-violet-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-violet-700 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-violet-900 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"'>Save</button></div>
          </div>
        </div>
  </div>
</section>
  )
}

export default Settings;
