import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import iconSettings from '../../static/icons/settings.svg'
import iconDashboard from '../../static/icons/dashboard.svg'
import iconTier from '../../static/icons/tier.svg'
import iconCoupon from '../../static/icons/coupons.svg'
import iconWallet from '../../static/icons/wallets.svg'
import iconNotification from '../../static/icons/notification.svg'
import iconCampaign from '../../static/icons/campaign.svg'
import iconUsers from '../../static/icons/users.svg'
import iconProfile from '../../static/icons/profile.svg'
import logo from '../../static/logo.svg'

const sidebarNavItems = [
    {
        display: 'Dashboard Home',
        icon: iconDashboard,
        to: '/',
        section: ''
    },
    {
        display: 'Tier Management',
        icon: iconTier,
        to: '/tiers',
        section: 'started'
    },
    {
        display: 'Campaign Management',
        icon: iconCampaign,
        to: '/campaigns',
        section: 'calendar'
    },
    {
        display: 'Customer Management',
        icon: iconUsers,
        to: '/customers',
        section: 'customers'
    },
    {
        display: 'Coupon Management',
        icon: iconCoupon,
        to: '/coupons',
        section: 'order'
    },
    {
        display: 'Wallet Management',
        icon: iconWallet,
        to: '/wallets',
        section: 'order'
    },/*
    {
        display: 'Notification',
        icon: iconNotification,
        to: '/notification',
        section: 'order'
    },*/
    {
        display: 'Profile',
        icon: iconProfile,
        to: '/profile',
        section: 'order'
    },
    {
        display: 'Settings',
        icon: iconSettings,
        to: '/settings',
        section: 'order'
    },
]

const Sidebar = () => {
    const [mobile, setMobile] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
        }, 50);
    }, []);

    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1];
        const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return <div className='h-full'>

<button onClick={() => setMobile(!mobile)} type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
   <span className="sr-only">Menu</span>
   <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
   </svg>
</button>

<div className={"fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full " + (!mobile ? 'sm:translate-x-0' : '') } aria-label="Sidebar">
   <div className="h-full px-3 py-4 overflow-y-auto bg-gray-900">
   <div className='mt-3 mb-10'>
        <div className='float-left mr-4'><img src={logo} width={50} alt="" /></div>
        <div className=' text-white text-2xl font-semibold py-2'>Ovo Loyalty</div>
    </div>
      <ul className="space-y-2 font-normal">
        <li className='mb-6'>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="search" className="block w-full p-2 pl-10 text-sm text-white placeholder-white border border-gray-800 rounded-md bg-gray-800 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
            </div>
        </li>

        { sidebarNavItems.map((item, index) => (
            <li>
                <Link to={item.to} key={index} className="flex items-center p-2 rounded-lg text-white  hover:bg-gray-600 group">
                <img src={item.icon} alt='' />
                <span className="flex-1 ml-3 whitespace-nowrap">{item.display}</span>
                </Link>
            </li>
            ))
        }

      </ul>
   </div>
</div>





    </div>;
};

export default Sidebar;