import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { fakerDE as faker } from '@faker-js/faker';
import dash from '../services/dashboard.service'

import icon1 from '../static/icons/icon1.svg'
import icon2 from '../static/icons/icon2.svg'
import icon3 from '../static/icons/icon3.svg'
import icon4 from '../static/icons/icon4.svg'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



function Dashboard() {

  const [chartData, setChartData] = useState();
  const [chartLabel, setChartLabel] = useState([]);
  const [chart1, setChart1] = useState(true);
  const [chart2, setChart2] = useState(true);
  const [chart3, setChart3] = useState(true);

  
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Aylara Göre Yeni Kullanıcılar',
      },
    },
  };
  
  var labels = [202311,202310,202309,202308,202307,202306,202305,202304,202303,202302,202301,202212];
  const data1 = {
    labels,
    datasets: [
      {
        label: 'Aylara Göre Yeni Kullanıcıla',
        data: [3,2,3,2,9,5,2,1,3,0,1,1],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const data2 = {
    labels,
    datasets: [
      {
        label: 'Aylara Göre Cüzdan Yoluyla Yapılan Toplam Ödemeler',
        data: [100,500,600,250,150,350,250,150,380,0,80,60],
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    ],
  };

  const data3 = {
    labels,
    datasets: [
      {
        label: 'Başarısız olan Aylık Siparişler',
        data: [0,1,0,1,4,10,1,2,5,0,5,1],
        borderColor: 'rgb(100, 100, 100)',
        backgroundColor: 'rgba(100, 100, 100, 0.5)',
      },
    ],
  };
  
  


return (
<section className="h-screen">



  <div className="container h-full px-0 py-0">
        <div className='text-3xl font-semibold text-gray-700'>Dashboard</div>
        <div className='text-base font-medium text-gray-500'>Dashboard / Home</div>

        <div className='grid grid-cols-4 gap-4 mt-5'>
      <div className='container border bg-white rounded-lg min-h-[10%] shadow-sm'>
        <div className=' m-5'>
        <img src={icon1} alt='' className='float-left mr-5' />
        <div className='text-gray-400 text-md font-semibold'>Toplam İşlem</div>
        <div className='grid grid-cols-2 ml-5'>
          <div className='text-left font-bold text-lg'>0</div>
          <div className='text-right text-green-600 text-lg'>+0</div>
        </div>

        </div>
      </div>
        <div className='container border bg-white rounded-lg min-h-[10%] shadow-sm'>
        <div className=' m-5'>
        <img src={icon2} alt='' className='float-left mr-5' />
        <div className='text-gray-400 text-md font-semibold'>İptal Edilen İşlemler</div>
        <div className='grid grid-cols-2 ml-5'>
          <div className='text-left font-bold text-lg'>0</div>
          <div className='text-right text-green-600 text-lg'>+0</div>
        </div>

        </div>
      </div>
      <div className='container border bg-white rounded-lg min-h-[10%] shadow-sm'>
        <div className=' m-5'>
        <img src={icon3} alt='' className='float-left mr-5' />
        <div className='text-gray-400 text-md font-semibold'>Yeni Üyeler</div>
        <div className='grid grid-cols-2 ml-5'>
          <div className='text-left font-bold text-lg'>0</div>
          <div className='text-right text-green-600 text-lg'>+0</div>
        </div>

        </div>
      </div>
        <div className='container border bg-white rounded-lg min-h-[10%] shadow-sm'>
        <div className=' m-5'>
          <img src={icon4} alt='' className='float-left mr-5' />

        <div className='text-gray-400 text-md font-semibold'>Toplam Satış Tutarı</div>
        <div className='grid grid-cols-2 ml-5'>
          <div className='text-left font-bold text-lg'>0</div>
          <div className='text-right text-green-600 text-lg'>+0</div>
        </div>
        </div>
      </div>
    </div>

        <div className='container mt-8 border bg-white rounded-lg min-h-[10%] shadow-sm'>
          <div className='grid grid-cols-2 m-5'>
          <div className='text-gray-700 text-xl font-semibold'>General Overview</div>
            <div className='text-right'></div>
            {chart1 &&
            <Line data={data1} />
            }
            <br /><br />
            {chart2 &&
            <Line data={data2} />
            }
            <br /><br />
            {chart3 &&
            <Line data={data3} />
            }
          </div>
        </div>
  </div>
</section>
  )
}

export default Dashboard;
